@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #242323;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
	margin: 0;
	height: 100vh;
	background-color: #1d1c1c;
	color: whitesmoke;
	font-family: 'Roboto', sans-serif;
}

#root {
	height: 100%;
}

.app {
	height: 100%;
	display: flex;
	position: relative;

	.btn-toggle {
		cursor: pointer;
		width: 35px;
		height: 35px;
		color: #fff;
		text-align: center;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		display: none;
		padding: 8px;
	}

	.sidebar-btn-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		.sidebar-btn {
			padding: 1px 15px;
			border-radius: 40px;
			background: rgba(255, 255, 255, 0.05);
			color: #adadad;
			text-decoration: none;
			margin: 0 auto;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-overflow: ellipsis;
			overflow: hidden;
			span {
				margin-left: 5px;
				font-size: 13px;
			}
			&:hover {
				color: $highlight-color;
			}
		}
	}

	.collapsed {
		.sidebar-btn {
			display: inline-block;
			border-radius: 50%;
			width: 35px;
			height: 35px;
			line-height: 40px;
			padding: 0;
		}
	}

	main {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		header {
			margin-top: 15px;
			margin-left: 35px;
			border-bottom: 1px solid #efefef;
			h1 {
				display: flex;
				align-items: center;
				transform: translateX(0px);
				color: rgb(171, 179, 179);
				margin-top: 35px;
			}
			h2 {
				display: flex;
				align-items: center;
				transform: translateX(0px);
				color: rgb(171, 179, 179);
				margin-top: 15px;
			}
		}
		.code-snippet {
			background: #e7e0e0;
			border: 1px solid rgb(146, 141, 141);
			color: #666;
			page-break-inside: avoid;
			font-family: monospace;
			font-size: 15px;
			line-height: 1.6;
			margin-bottom: 1.6em;
			max-width: 100%;
			overflow: auto;
			padding: 1em 1.5em;
			display: block;
			word-wrap: break-word;
			border-radius: 5px;
		}
		a {
			color: lightblue;
			text-decoration: none;
		}
	}
	.social-bagdes {
		margin-top: 10px;
		img {
			margin: 5px;
		}
	}

	.block {
		display: block;
		margin-left: 35px;
		margin-bottom: 24px;
		font-size: 18px;
		max-width: 700px;
		> span {
			margin-left: 10px;
		}
	}

	.projects {
		margin: 25px;
	}

	.intro {
		text-align: center;
		margin: auto;
		margin-top: 210px;
	}

	.head {
		text-align: left;
		margin-right: auto;
	}

	.swapper {
		background-color: #adadad;
		border: none;
		color: white;
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		transition-duration: 0.4s;
	}

	// .swapper:hover {
	// 	background-color: #363535;
	// 	color: black;
	// }

	button:disabled,
	button[disabled] {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}

	&.rtl {
		direction: rtl;
		text-align: right;

		header {
			h1 {
				transform: translateX(20px);
			}
		}
		.block {
			direction: rtl;
			> span {
				margin-left: 0;
				margin-right: 10px;
			}
		}
		.sidebar-btn-wrapper {
			.sidebar-btn {
				span {
					margin-left: 0;
					margin-right: 5px;
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		overflow-x: hidden;
		header {
			h1 {
				font-size: 24px;
			}
		}
	}
	@media (max-width: $breakpoint-md) {
		.btn-toggle {
			display: flex;
		}

		&.rtl {
			.btn-toggle {
				margin-left: auto;
			}
		}
		main {
			header {
				margin-top: 15px;
				margin-left: 25px;
				border-bottom: 1px solid #efefef;
				h1 {
					display: flex;
					align-items: center;
					transform: translateX(0px);
					color: rgb(171, 179, 179);
					margin-top: 35px;
				}
				h2 {
					display: flex;
					align-items: center;
					transform: translateX(0px);
					color: rgb(171, 179, 179);
					margin-top: 15px;
				}
			}
		}

		.block {
			display: block;
			margin-left: 25px;
			margin-bottom: 24px;
			font-size: 18px;
			max-width: 300px;
			> span {
				margin-left: 10px;
			}
		}
	}
}
